import RoomService, {
  TokboxRecordResponse,
  TwilioRecordResponse
} from "@/services/room.service";
import {
  IZoomSessionListResponse,
  IZoomSessionRecord
} from "@/types/interfaces/zoom.interface";
import { reactive, ref, toRefs } from "@vue/composition-api";
import moment from "moment";
interface UseRecordTableState {
  loading: boolean;
  records: {
    twilio: TwilioRecordResponse[];
    tokbox: TokboxRecordResponse[];
    zoom?: IZoomSessionListResponse;
  };
  viewEngine?: string;
  columns: {
    twilio: object[];
    tokbox: object[];
    zoom: object[];
  };
  pagination: Pagination;
  filter: {
    start?: string;
    end?: string;
  };
  showRecordZoomList: boolean;
  isLoadingZoomSession: boolean;
  zoomSessionRecord?: IZoomSessionRecord;
}
interface UseRecordTableParams {
  roomId: string;
}
interface Pagination {
  pageSize: number;
  // current: number;
  // total: number;
}

interface IDownloadParams {
  url: string;
  accessToken: string;
}

const columns = {
  twilio: [
    {
      title: "sid",
      dataIndex: "sid",
      width: "200px",
      scopedSlots: { customRender: "twilioSid" }
    },
    {
      title: "Date",
      width: "150px",
      dataIndex: "dateCreated",
      scopedSlots: { customRender: "date" }
    },
    {
      title: "Duration",
      dataIndex: "duration",
      scopedSlots: { customRender: "duration" }
    },
    {
      title: "Action",
      dataIndex: "id",
      scopedSlots: { customRender: "action" }
    }
  ],
  tokbox: [
    {
      title: "tokboxSid",
      dataIndex: "sessionId",
      width: "200px",
      scopedSlots: { customRender: "sessionId" }
    },
    {
      title: "Duration",
      dataIndex: "duration",
      scopedSlots: { customRender: "duration" }
    },
    {
      title: "Action",
      dataIndex: "url",
      scopedSlots: { customRender: "action" }
    }
  ],
  zoom: [
    {
      title: "session ID",
      key: "sessionId",
      dataIndex: "sessionId",
      width: "200px",
      scopedSlots: { customRender: "sessionId" }
    },
    {
      title: "Start",
      key: "startTime",
      width: "150px",
      dataIndex: "startTime",
      scopedSlots: { customRender: "startDate" }
    },
    {
      title: "End",
      key: "endTime",
      width: "150px",
      dataIndex: "endTime",
      scopedSlots: { customRender: "endDate" }
    },
    {
      title: "Duration",
      key: "duration",
      dataIndex: "duration",
      scopedSlots: { customRender: "duration" }
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "hasRecording",
      scopedSlots: { customRender: "action" }
    }
  ]
};
const useRecordTable = ({ roomId }: UseRecordTableParams) => {
  const state = reactive<UseRecordTableState>({
    loading: true,
    records: {
      twilio: [],
      tokbox: [],
      zoom: undefined
    },
    viewEngine: "twilio",
    filter: {
      start: undefined,
      end: undefined
    },
    columns,
    pagination: {
      pageSize: 5
    },
    showRecordZoomList: false,
    isLoadingZoomSession: false,
    zoomSessionRecord: undefined
  });

  const fetchTwilioRecord = async () => {
    const data = await RoomService.getTwilioRecord({
      roomId,
      start: state.filter.start
        ? moment(state.filter.start).toISOString()
        : undefined,
      end: state.filter.end
        ? moment(state.filter.end)
            .endOf("d")
            .toISOString()
        : undefined
    });
    state.records.twilio = data;
  };

  const createComposition = async (roomSid: string) => {
    const res = await RoomService.createTwilioComposition(roomId, roomSid);
    if (res) {
      fetchRecord();
    }
    return res;
  };

  const removeComposition = async (compositionSid: string) => {
    const res = await RoomService.removeTwilioComposition(
      roomId,
      compositionSid
    );
    if (res) {
      fetchRecord();
    }
    return res;
  };

  const fetchTokboxRecord = async () => {
    const data = await RoomService.getTokboxRecord(roomId);
    state.records.tokbox = data;
  };

  const fetchZoomSession = async () => {
    const data = await RoomService.getZoomSessionListbyRoomId({
      roomId,
      start: state.filter.start
        ? moment(state.filter.start).toISOString()
        : undefined,
      end: state.filter.end
        ? moment(state.filter.end)
            .endOf("d")
            .toISOString()
        : undefined
    });
    state.records.zoom = data;
  };

  const fetchZoomRecordBySessionId = async (sessionId: string) => {
    state.showRecordZoomList = true;
    state.isLoadingZoomSession = true;
    const data = await RoomService.getZoomRecordBySessionId({
      roomId,
      sessionId
    });
    state.isLoadingZoomSession = false;
    if (data) {
      state.zoomSessionRecord = data;
    }
  };

  const downloadZoomFile = async (url: string) => {
    const data = await RoomService.getZoomRecordFile(url);
    return data?.downloadUrl;
  };

  const fetchRecord = async () => {
    state.loading = true;
    switch (state.viewEngine) {
      case "twilio":
        await fetchTwilioRecord();
        break;
      case "tokbox":
        await fetchTokboxRecord();
        break;
      case "zoom":
        await fetchZoomSession();
        break;

      default:
        break;
    }
    state.loading = false;
  };

  return {
    ...toRefs(state),
    createComposition,
    fetchRecord,
    fetchZoomRecordBySessionId,
    removeComposition,
    downloadZoomFile,
  };
};

export default useRecordTable;
