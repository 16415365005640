import { detectDevice, DetectDeviceResponse } from "@/helpers/browser-detect";
import { onMounted, reactive, toRefs } from "@vue/composition-api";

interface UseDisplayBrowserState {
  rawResult?: DetectDeviceResponse;
}

const useDisplayBrowser = (userAgent: string) => {
  const state = reactive<UseDisplayBrowserState>({
    rawResult: {}
  });
  onMounted(() => {
    state.rawResult = detectDevice(userAgent);
  });

  return { ...toRefs(state) };
};

export default useDisplayBrowser;
