
// eslint-disable-next-line no-unused-vars
import { IZoomSessionRecord } from "@/types/interfaces/zoom.interface";
import { Emit, Vue, Component, Prop, Provide } from "vue-property-decorator";
import { formatBytes } from "@/helpers/number.helper.ts";
import RoomDescItem from "@/components/room/RoomDescriptionItem.vue";

@Component({ components: { RoomDescItem } })
export default class RoomZoomRecord extends Vue {
  @Prop({ type: Object, required: true })
  private session!: IZoomSessionRecord;

  @Provide() viewTab = "";

  @Provide() columns = [
    {
      title: "View Mode",
      dataIndex: "recordingType",
      scopedSlots: { customRender: "recordingType" }
    },
    {
      title: "Size",
      dataIndex: "fileSize",
      scopedSlots: { customRender: "fileSize" }
    },
    {
      title: "Type",
      dataIndex: "fileType",
      scopedSlots: { customRender: "fileType" }
    },
    {
      title: "Link",
      dataIndex: "downloadUrl",
      scopedSlots: { customRender: "downloadUrl" }
    }
  ];

  @Provide() participantColumns = [
    {
      title: "File name",
      dataIndex: "fileName",
      scopedSlots: { customRender: "fileName" }
    },
    {
      title: "Size",
      dataIndex: "fileSize",
      scopedSlots: { customRender: "fileSize" }
    },
    {
      title: "Type",
      dataIndex: "fileType",
      scopedSlots: { customRender: "fileType" }
    },
    {
      title: "Link",
      dataIndex: "downloadUrl",
      scopedSlots: { customRender: "downloadUrl" }
    }
  ];

  @Emit("click-download")
  onDownloadFile(): void {}

  @Emit("click-copy")
  onCopyLink(): void {}

  mounted() {
    if (this.session.recordingFiles.length) {
      this.viewTab = "recording";
    } else if (this.session.participantAudioFiles.length) {
      this.viewTab = "audio";
    } else if (this.session.participantVideoFiles.length) {
      this.viewTab = "video";
    }
  }

  byteConvertor(bytes: number) {
    return formatBytes(bytes);
  }
}
