<template>
  <div>
    <a-row :gutter="24">
      <a-col :xs="24" :md="8" :lg="10">
        <a-card :title="`RoomId: ${$route.params.id}`">
          <div class="d-flex justify-content-end" slot="extra">
            <router-link
              class="ml-3"
              :to="{
                name: 'room-id-participate',
                params: { id: $route.params.id }
              }"
            >
              <a-button type="default" icon="user">
                Edit
              </a-button>
            </router-link>
          </div>
          <room-description v-if="room" :room-info="room" />
        </a-card>
      </a-col>
      <a-col :xs="24" :md="16" :lg="14">
        <a-tabs v-model="viewEngine" type="card" @change="fetchRecord">
          <a-tab-pane key="twilio" tab="Twilio">
            <template>
              <div class="d-flex my-3">
                <div class="mr-2">
                  <p class="m-1">Start Date</p>
                  <a-input
                    v-model="filter.start"
                    type="date"
                    :max="filter.end"
                    class="mr-1"
                    placeholder="start YYYY-MM-DD"
                    @change="fetchRecord"
                  ></a-input>
                </div>
                <div>
                  <p class="m-1">End Date</p>
                  <a-input
                    v-model="filter.end"
                    type="date"
                    :min="filter.start"
                    class="mr-1"
                    placeholder="end YYYY-MM-DD"
                    @change="fetchRecord"
                  ></a-input>
                </div>
              </div>
              <p class="text-danger">*Records limited at 100 records</p>
              <a-table
                :loading="tableLoading"
                :dataSource="records.twilio"
                :columns="columns.twilio"
                :pagination="pagination"
                size="middle"
                :row-key="record => record.sid"
              >
                <template slot="date" slot-scope="text">
                  <div>
                    {{ text | moment("DD/MM/YYYY HH:mm") }}
                  </div>
                </template>
                <template slot="duration" slot-scope="text">
                  <div>
                    {{ displayMinutes(text) }}
                  </div>
                </template>
                <template slot="action" slot-scope="text, record">
                  <template v-if="!record.recordParticipantsOnConnect">
                    <div class="record-action">
                      <div class="badge-danger">Unrecorded</div>
                    </div>
                  </template>
                  <template v-else-if="record.composition">
                    <a-tooltip placement="topLeft">
                      <template slot="title">
                        <span v-if="record.composition.status === 'completed'">
                          download
                        </span>
                        <span v-else>{{ record.composition.status }}</span>
                      </template>

                      <a
                        :href="record.composition.redirectTo"
                        target="_blank"
                        class="mr-3"
                      >
                        <a-button
                          type="primary"
                          shape="circle"
                          icon="download"
                          :disabled="record.composition.status !== 'completed'"
                        ></a-button>
                      </a>
                    </a-tooltip>
                    <a-popconfirm
                      title="Confirm for delete video record"
                      @confirm="
                        () => removeRecordTwilio(record.composition.sid)
                      "
                    >
                      <a-button
                        type="danger"
                        shape="circle"
                        icon="delete"
                        class="mr-3"
                      ></a-button>
                    </a-popconfirm>
                    <a-button
                      v-if="record.composition.status === 'completed'"
                      type="dashed"
                      v-clipboard="record.composition.redirectTo"
                      v-clipboard:success="() => $message.success('Copied')"
                    >
                      copy link
                    </a-button>
                  </template>

                  <template v-else>
                    <a-popconfirm
                      title="Confirm for request video record"
                      @confirm="() => createTwilioRecord(record.sid)"
                    >
                      <a-button
                        type="primary"
                        shape="circle"
                        icon="video-camera"
                      ></a-button>
                    </a-popconfirm>
                  </template>
                </template>
              </a-table>
            </template>
          </a-tab-pane>
          <a-tab-pane key="tokbox" tab="Tokbox" force-render>
            <a-table
              :loading="tableLoading"
              :data-source="records.tokbox"
              :pagination="pagination"
              :row-key="record => record.id"
              :columns="columns.tokbox"
            >
              <template slot="duration" slot-scope="text">
                <div>
                  {{ displayMinutes(text) }}
                </div>
              </template>
              <template slot="action" slot-scope="text">
                <a :href="text" target="_blank" class="mr-3">
                  <a-button type="primary" shape="circle" icon="download" />
                </a>

                <a-button
                  type="dashed"
                  v-clipboard="text"
                  v-clipboard:success="() => $message.success('Copied')"
                >
                  copy link
                </a-button>
              </template>
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="zoom" tab="Zoom" force-render>
            <div class="d-flex my-3">
              <div class="mr-2">
                <p class="m-1">Start Date</p>
                <a-input
                  v-model="filter.start"
                  type="date"
                  :max="filter.end"
                  class="mr-1"
                  placeholder="start YYYY-MM-DD"
                  @change="fetchRecord"
                ></a-input>
              </div>
              <div>
                <p class="m-1">End Date</p>
                <a-input
                  v-model="filter.end"
                  type="date"
                  :min="filter.start"
                  class="mr-1"
                  placeholder="end YYYY-MM-DD"
                  @change="fetchRecord"
                ></a-input>
              </div>
            </div>
            <div class="mb-3">
              <p class="text-danger">*Range of date maximum 1 month</p>
            </div>
            <div v-if="records.zoom">
              <p>
                Display session in
                {{ records.zoom.fromDate | moment("DD/MM/YYYY") }} -
                {{ records.zoom.toDate | moment("DD/MM/YYYY") }}
              </p>
            </div>

            <a-table
              :loading="tableLoading"
              :data-source="records.zoom ? records.zoom?.sessions : []"
              :pagination="pagination"
              :row-key="record => record.sessionId"
              :columns="columns.zoom"
            >
              <template slot="startDate" slot-scope="text">
                <div>
                  {{ text | moment("DD/MM/YYYY HH:mm:ss") }}
                </div>
              </template>
              <template slot="endDate" slot-scope="text">
                <div>
                  {{ text | moment("DD/MM/YYYY HH:mm:ss") }}
                </div>
              </template>
              <template slot="duration" slot-scope="text">
                <div>
                  {{ text }}
                </div>
              </template>
              <template slot="action" slot-scope="text, record">
                <template v-if="text">
                  <a-button
                    type="primary"
                    shape="circle"
                    icon="download"
                    class="mr-3"
                    @click="fetchZoomRecordBySessionId(record.sessionId)"
                  />
                </template>
                <template v-else>
                  <div class="record-action">
                    <div class="badge-danger">Unrecorded</div>
                  </div>
                </template>
              </template>
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </a-col>
    </a-row>

    <div class="mt-3">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="Logs">
          <a-table
            :loading="loading"
            :data-source="logs"
            :row-key="record => record.id"
            :columns="logColumns"
            :scroll="{ x: 1100 }"
            size="middle"
          >
            <template slot="participate" slot-scope="text">
              <div class="d-flex align-items-center">
                <div><a-avatar :src="text.avatar"></a-avatar></div>
                <div class="ml-2">{{ text.name }}</div>
              </div>
            </template>
            <template slot="role" slot-scope="text">
              {{ text.name }}
            </template>

            <template slot="action" slot-scope="text, record">
              <div>{{ text }}</div>
              <div v-if="text === 'report'">
                <a-tag
                  v-for="(comment, index) in record.comments"
                  :key="`comment-${record.id}-${index}`"
                  color="orange"
                >
                  <strong>{{ comment }}</strong>
                </a-tag>
              </div>
            </template>
            <template slot="time" slot-scope="text">
              {{ text | moment("DD/MM/YYYY HH:mm:ss") }}
            </template>

            <template slot="userAgent" slot-scope="text, record">
              <display-browser
                v-if="record.action !== 'leave'"
                :user-agent="text"
              />
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </div>

    <a-modal
      :visible="showRecordZoomList"
      title="Zoom Recording List"
      :footer="null"
      @cancel="
        () => {
          showRecordZoomList = false;
          zoomSessionRecord = undefined;
        }
      "
    >
      <div
        v-if="isLoadingZoomSession"
        class="py-5 px-3 d-flex justify-content-center align-items-center"
      >
        <a-spin />
      </div>
      <RoomZoomRecord
        v-if="zoomSessionRecord"
        :session="zoomSessionRecord"
        @click-download="clickDownloadZoomFile"
        @click-copy="copyLinkDownloadZoomFile"
      />
    </a-modal>
  </div>
</template>

<script>
import useRoomDetail from "@/composables/room/use-room-detail";
import RoomDescription from "@/components/room/RoomDescription";
import useRecordTable from "@/composables/room/use-record-table";
import DisplayBrowser from "@/components/common/DisplayBrowser";
import RoomZoomRecord from "@/components/room/RoomZoomRecord";
import { computed, watch } from "@vue/composition-api";
import moment from "moment";
export default {
  components: { RoomDescription, DisplayBrowser, RoomZoomRecord },
  setup(props, context) {
    const roomId = computed(() => context.root?.$route.params.id);
    const { loading, room, logs, logColumns } = useRoomDetail({
      roomId: roomId.value
    });

    const {
      loading: tableLoading,
      columns,
      pagination,
      records,
      filter,
      viewEngine,
      createComposition,
      showRecordZoomList,
      zoomSessionRecord,
      fetchRecord,
      fetchZoomRecordBySessionId,
      removeComposition,
      downloadZoomFile,
      isLoadingZoomSession
    } = useRecordTable({
      roomId: roomId.value
    });

    watch(
      () => room.value,
      () => {
        if (room.value && (!filter.value.start || !filter.value.end)) {
          viewEngine.value = room.value?.isMainEngine
            ? room.value?.engine
            : room.value?.fallbackEngine;
          const duration = moment(room.value.endTime).diff(
            room.value.startTime,
            "h"
          );
          if (duration > 1) {
            filter.value.start = moment()
              .subtract(7, "d")
              .format("YYYY-MM-DD");
            filter.value.end = moment().format("YYYY-MM-DD");
          } else {
            filter.value.start = moment(room.value.startTime).format(
              "YYYY-MM-DD"
            );
            filter.value.end = moment(room.value.startTime).format(
              "YYYY-MM-DD"
            );
          }

          fetchRecord();
        }
      }
    );

    return {
      // useRoomDetail
      loading,
      room,
      logs,
      logColumns,

      // useRecordTable
      tableLoading,
      records,
      filter,
      columns,
      pagination,
      viewEngine,
      createComposition,
      fetchRecord,
      fetchZoomRecordBySessionId,
      showRecordZoomList,
      zoomSessionRecord,
      removeComposition,
      downloadZoomFile,
      isLoadingZoomSession
    };
  },
  methods: {
    createTwilioRecord(roomSid) {
      const res = this.createComposition(roomSid);
      if (res) {
        this.$message.success("Video created successfull");
      }
    },
    async removeRecordTwilio(compositioSid) {
      const res = await this.removeComposition(compositioSid);
      if (res) {
        this.$message.success("Deleted Successfull");
      } else {
        this.$message.error("Deleted Fail!");
      }
    },
    displayMinutes(second) {
      if (second < 3600) return `${Math.floor(second / 60)}m ${second % 60}s`;
      else {
        const min = Math.floor((second % 3600) / 60);
        return `${Math.floor(second / 3600)}h ${min}m ${second % 60}s`;
      }
    },
    async clickDownloadZoomFile(url) {
      const returnLink = await this.downloadZoomFile(url);
      if (returnLink) {
        window.open(returnLink, "_blank");
      }
    },
    async copyLinkDownloadZoomFile(url) {
      const returnLink = await this.downloadZoomFile(url);
      if (returnLink) {
        await navigator.clipboard.writeText(returnLink);
        this.$message.success("Copy link successfull");
      } else {
        this.$message.error("Cannot access download file");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.record-action {
  display: flex;
  .badge-danger {
    padding: 4px 16px;
    border-radius: 6px;
    color: white;
    background: $danger;
    font-weight: 600;
    user-select: none;
  }
}
</style>
