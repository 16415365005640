<template>
  <div
    v-html="require(`!svg-inline-loader!@/assets/img/svg/icon-${icon}.svg`)"
    class="svg-container"
  ></div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null
    },
    inheritFill: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    }
  },
  mounted() {
    if (this.$el.firstElementChild.nodeName === "svg") {
      const svgElement = this.$el.firstElementChild;
      if (this.inheritFill) {
        recursivelyRemoveFill(svgElement);
      }

      if (this.width) svgElement.setAttribute("width", this.width);
      if (this.height) svgElement.setAttribute("height", this.height);
      svgElement.classList.add("svg-class");
    }
  }
};

function recursivelyRemoveFill(el) {
  if (!el) {
    return;
  }
  el.setAttribute("fill", "currentColor");
  [].forEach.call(el.children, child => {
    recursivelyRemoveFill(child);
  });
}
</script>

<style lang="scss" scoped>
.svg-container {
  display: inline;
}
.svg-class {
  vertical-align: middle;
}
</style>
