import { UAParser } from "ua-parser-js";
export interface DetectDeviceResponse {
  browser?: object;
  version?: string;
  device?: object;
  os?: object;
  ua?: string;
}
export const detectBrowser = (userAgent: string) => {
  if (userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (userAgent.indexOf("Firefox") !== -1) {
    return "Mozilla Firefox";
  } else if (userAgent.indexOf("MSIE") !== -1) {
    return "Internet Exploder";
  } else if (userAgent.indexOf("Edge") !== -1) {
    return "Edge";
  } else if (userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (userAgent.indexOf("Opera") !== -1) {
    return "Opera";
  } else if (userAgent.indexOf("YaBrowser") !== -1) {
    return "YaBrowser";
  } else {
    return "Others";
  }
};

const parseBrowserToIcon = (name: string) => {
  if (name.indexOf("Safari") !== -1) {
    return "safari";
  } else if (name.indexOf("Opera") !== -1) {
    return "opera";
  } else if (name.indexOf("Chrome") !== -1) {
    return "chrome";
  } else if (name.indexOf("Edge") !== -1) {
    return "edge";
  } else {
    return "question-circle";
  }
};

const parseOSToIcon = (name: string) => {
  if (name.indexOf("Android") !== -1) {
    return "android";
  } else if (name.indexOf("Window") !== -1) {
    return "window";
  } else if (name.indexOf("Mac") !== -1 || name.indexOf("iOS") !== -1) {
    return "mac";
  } else {
    return "question-circle";
  }
};

const parseDeviceToIcon = (name: string) => {
  return "iphone";
  // if (name.indexOf("Apple") !== -1) {
  //   return "iphone";
  // } else {
  //   return "question-circle";
  // }
};
export const detectDevice = (userAgent: string): DetectDeviceResponse => {
  const parser = new UAParser();
  const result = parser.setUA(userAgent).getResult();
  const all = <DetectDeviceResponse> {
    browser: {
      ...result.browser,
      icon: parseBrowserToIcon(result.browser?.name || "")
    },
    os: { ...result.os, icon: parseOSToIcon(result.os?.name || "") },
    ua: result.ua
  };
  if (result.device.type) {
    all.device = {
      ...result.device,
      icon: parseDeviceToIcon(result.device?.vendor || "")
    };
  }
  return all;
};
