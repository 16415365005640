
import SvgIcon from "@/components/common/SvgIcon.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import useDisplayBrowser from "@/composables/use-display-browser.ts";

@Component({ components: { SvgIcon } })
export default class DisplayBrowser extends Vue {
  @Prop({ type: String, default: "" })
  private userAgent?: string;

  // eslint-disable-next-line no-unused-vars
  setup(props: { userAgent: string }, context: any) {
    const { rawResult: result } = useDisplayBrowser(props.userAgent);

    return { result };
  }
}
